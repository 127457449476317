.processing-alert-info-container {
  margin: 60px 0px;
}



.processing-alert-info-card {
  box-shadow: none !important;
  background-color: #f0f1f4;
  padding: 10px 0;
  border-radius: 5px;
}

.processing-alert-info-vehicle.complete .processing-alert-info-card,
.processing-alert-info-vehicle.inProcess .processing-alert-info-card {
  box-shadow: none;
  background-color: #fff;
  border: solid #17788d;
}

.status-label {
  background: #f9f9f9;
  text-align: right;
  font-size: 10px;
  margin-top: 5px;
}

.processing-alert-info-vehicle.complete .status-label span{
  color: #17788d;
  font-weight: bold;
}

.status-label img {
  height: 10px;
  width: 12px;
  display: none;
}
.processing-alert-info-vehicle.complete .status-label img {
  display: inline-block;
}

.status-label span {
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  margin-left: 5px;
  color: #707c89;
}

.processing-alert-info-card-title-images {
  text-align: center;
}

/* .processing-alert-info-card-title-images .card-img-car {
  margin-left: 60px;
  padding-top: 10px;
  height: 18px;
  width: 31px;
} */

.processing-alert-info-card-title-images .card-img-icon {
  margin-left: 40px;
  padding-bottom: 7px;
  display: inline;
}

.processing-alert-info-card-title {
  font-family: "Roboto";
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #434362;
  margin: 0;
}

.processing-alert-info-card-text {
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #707c89;
  margin: 0;
}

.processing-alert-info-section-cards {
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 0 20px;
  max-width: 700px;
  margin: 0 auto 40px auto;
}
.processing-alert-info-services{
  padding: 0 20px;
  max-width: 700px;
  margin: auto;
}


@media (max-width: 769px) {
  .processing-alert-info-container {
    margin-top: 0;
    margin-bottom: 90px;
  }
}
